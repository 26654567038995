import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import e from 'express';
import { MessagesService } from '@app/core/services/messages/messages.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: FormGroup;
  errorMessage!: string;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private _msg : MessagesService
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20),
        ],
      ],
    });

    const token = localStorage.getItem('ecotoken');
    const rules = localStorage.getItem('userRules');
    if (token) {
      localStorage.removeItem('ecotoken');
    }
    if (rules) {
      localStorage.removeItem('userRules');
    }
  }

  login() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      this.authService
        .login({ username, password }, { username, password })
        .subscribe({
          next: (response) => {
            if (response.responseData) {
              
              this.authService.handleLoginResponse(response);
              localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE','en')
              this.router.navigate(['/en']);
            } else {
              console.log(response)
              this.errorMessage = response.errorMessageAr;
              this._msg.showErrorMessage(response.errorMessageAr,response.errorMessageAr)
              setTimeout(()=>{this.errorMessage = ""} , 3000)
              // console.error('Wrong credentials');
            }
          },
          error: (error) => {
            this.errorMessage =
              'غير مصرح بالدخول. يرجى التحقق من بيانات الاعتماد الخاصة بك.';
            if (error.error && error.error.error === 'Unauthorized') {
              console.error('Unauthorized', error);
            }
          },
        });
    }
  }
}
