import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MapService } from '../map/map.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  metaData = {
    title: '' || '',
    og_title: '' || '',
    description: '',
    og_description: '' || '',
    keywords: '' || '',
    og_url: ``,
    og_image: '',
    twitter_title: '' || '',
    twitter_description: '' || '',
    twitter_image: ''
  };
  constructor(
    private http: HttpClient,
    private meta: Meta,
    private title: Title,
    private location: Location,
    private renderer: Renderer2,
    private router:Router,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
  }

  getPageDetails(slug: string): Observable<any> {
    return this.http.get(`${environment.url}page/${slug}`);
  }

  createMapScript(){
    const script = this.document.createElement('script');
    script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBgPlLlB6uJSbGBAhlircYKudeeuGVtWfs');
    script.setAttribute( 'async', '');
    script.setAttribute('defer', '');

    const script2 = this.renderer.createElement('script');
    script2.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBgPlLlB6uJSbGBAhlircYKudeeuGVtWfs&libraries=places');
    script2.setAttribute( 'async', '');
    script2.setAttribute('defer', '');
    script.appendChild(this.document.body, script);
    script2.appendChild(this.document.body, script2);
    console.log(script);
    console.log(script2)

  }

  addPagemMeta(res: any): void {

    this.meta.updateTag({ name: 'description', content: `${res.description}` });
    this.meta.updateTag({ property: 'og:title', content: `${res.og_title}` });
    this.meta.updateTag({ property: 'og:image', content: `${res.og_image}` });
    this.meta.updateTag({ property: 'og:url', content: `${this.location.path()}` });
    this.meta.updateTag({ property: 'og:description', content: `${res.og_description}` });

    this.meta.updateTag({ property: 'og:title', content: `${res.og_title}` });
    this.title.setTitle(res.title ? res.title : '');
  }


  updateCanonicalUrl(url: string) {
    // const link: HTMLLinkElement = this.renderer.createElement('link');
    const head = this.document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null
      if (element == null) {
        element = this.document.createElement('link') as HTMLLinkElement;
        // head.appendChild(element);
        this.renderer.appendChild(this.document.head, element);
      }


      element.setAttribute('rel', 'canonical')
      element.setAttribute('href', url)



  }

  updateAlternateUrl(url:string){
      const head = this.document.getElementsByTagName('head')[0];
      let elementEN: HTMLLinkElement= this.document.querySelector(`link[hreflang='en-EG']`) || null;
      let elementAR: HTMLLinkElement= this.document.querySelector(`link[hreflang='ar-EG']`) || null;
      let enUrl = ``
      let arUrl = ``
        if (elementAR == null ) {
          elementAR = this.document.createElement('link') as HTMLLinkElement;
         head.appendChild(elementAR)
        }
        if (elementEN == null) {
          elementEN = this.document.createElement('link') as HTMLLinkElement;
          head.appendChild(elementEN);
        }
      elementEN.setAttribute('rel','alternate')
      elementEN.setAttribute('hreflang','en-EG')
      elementAR.setAttribute('rel','alternate')
      elementAR.setAttribute('hreflang','ar-EG')
      // let link = this.location.path().split('/')

        if(url != ''){
          elementEN.setAttribute('href',enUrl+'/'+url)
          elementAR.setAttribute('href',arUrl+'/'+url)
        }
        else{
          elementEN.setAttribute('href',enUrl)
          elementAR.setAttribute('href',arUrl)
        }



  }

  updateAlternateXdefault(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      const head = this.document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement = this.document.querySelector(`link[hreflang='x-default']`) || null
      if (element == null) {
        element = this.document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'alternate')
      element.setAttribute('hreflang', 'x-default')
      element.setAttribute('href', url)
    }
  }

  createPaginationURL(res: any) {
    if (isPlatformBrowser(this.platformId)) {
      let url = new URL(this.document.URL);
      let next_pagination_url: string = '';
      let prev_pagination_url: string = '';

      this.removePaginationLinks();

      // create next link
      if (res.next_page_url) {
        next_pagination_url = `${url.origin}${url.pathname}${!url.searchParams.get('page') ? '?page=2' : '?page=' + (+url.searchParams.get('page')! + 1)}`.replace('http://', 'https://');
        next_pagination_url.endsWith('?page=1') ? next_pagination_url = next_pagination_url.replace('?page=1', '') : null;
        this.createLink('next', 'next_pagination', next_pagination_url);
      }

      // create prev link
      if (res.prev_page_url) {
        prev_pagination_url = `${url.origin}${url.pathname}${!url.searchParams.get('page') ? '' : '?page=' + (+url.searchParams.get('page')! - 1)}`.replace('http://', 'https://');
        prev_pagination_url.endsWith('?page=1') ? prev_pagination_url = prev_pagination_url.replace('?page=1', '') : null;
        this.createLink('prev', 'prev_pagination', prev_pagination_url);
      }
    }

  }

  removePaginationLinks(): void {
    if (isPlatformBrowser(this.platformId)) {
      let prev_pagination = this.document.getElementById('prev_pagination');
      let next_pagination = this.document.getElementById('next_pagination');
      if (prev_pagination) {
        prev_pagination.parentNode.removeChild(prev_pagination);
      }
      if (next_pagination) {
        next_pagination.parentNode.removeChild(next_pagination);
      }
    }


  }

  createLink(rel: any, id: any, url: any): void {
    if (isPlatformBrowser(this.platformId)) {
      let link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', rel);
      this.document.head.appendChild(link);
      link.setAttribute('href', url);
      link.setAttribute('id', id);
    }


  }

  insertPageSchema(schema: Record<string, any>, className = 'structured-data-website'): void {
    if (isPlatformBrowser(this.platformId)) {
      if (className != 'single-family-residence') {
        let script: HTMLScriptElement | any;
        let shouldAppend = false;
        if (this.document.head.getElementsByClassName(className).length) {
          script = this.document.head.getElementsByClassName(className)[0];
        } else {
          script = this.document.createElement('script');
          shouldAppend = true;
        }
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        if (shouldAppend) {
          this.document.head.appendChild(script);
        }
      } else {
        let script: HTMLScriptElement | any;
        script = this.document.createElement('script');
        script.setAttribute('class', className);
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schema);
        this.document.head.appendChild(script);
      }
    }


  }

  FAQsSchema = (metaFAQs: any[]) => {
    let FAQs: any[] = [];
    metaFAQs.forEach(faq => {
      if (faq.title) {
        FAQs.push({
          "@type": "Question",
          "name": faq.title,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.description
          }
        })
      }
    });

    return {
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      "mainEntity": FAQs
    }
  }

}
