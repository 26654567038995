import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DOCUMENT } from '@angular/common';
import { MessagesService } from '../../services/messages/messages.service';
import { SpinnerService } from '../../services/spinner.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private localizeRouterService: LocalizeRouterService,
    private spinnerService: SpinnerService,
    @Inject(DOCUMENT) private dom: any,
    private router: Router,
    private messagesService: MessagesService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.spinnerService.show();
    let languageFromUrl;
    this.dom.URL.includes('/en')
      ? (languageFromUrl = 'en')
      : (languageFromUrl = 'ar');
    const lang =
      this.localizeRouterService.parser.currentLang || languageFromUrl || 'en';
    const token = localStorage.getItem('ecotoken');

    const transformedReq = request.clone({
      setHeaders: {
        lang: lang,
        'Access-Control-Allow-Origin': 'http://localhost:4200',
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(transformedReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 200) {
          if (['POST', 'PUT', 'DELETE'].includes(request.method)) {
            if (event.body.responseData && event.body.responseData.result) {
              this.messagesService.showSuccessMessage(
                event.body.responseData.result,
                3000
              );
            }
          }
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          const localizedRoute =
            this.localizeRouterService.translateRoute('/login');
          this.router.navigate([localizedRoute]);
        } else {
          this.messagesService.showErrorMessage(
            err,
            'An error occurred during the API call'
          );
        }
        return throwError(() => err);
      }),
      finalize(() => {
        this.spinnerService.hide();
      })
    );
  }
}
