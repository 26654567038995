import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/shared/components/custom-snackbar/custom-snackbar.component';

import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private snackBar: MatSnackBar) { }
  show(){
   this.showToastWithout('Hello Mamoud' , '#14A44D ' , '#fff')
  }
  showSuccessMessage(message: string, duration: number = 5000) {
    this.showToastWithout(message , '#14A44D ' , '#fff')
 
    // this.snackBar.openFromComponent(CustomSnackbarComponent, {
    //   horizontalPosition: 'end',
    //   verticalPosition: 'bottom',
    //   duration: duration,
    //   data: {
    //     message: message,
    //     type: 'success'
    //   }
    // });
  }
  showToastWithout(title: string, backgroundColor: string, textColor: string) {
    const progresscolor = ''
    const Toast = Swal.mixin({
      background :backgroundColor,
      color:textColor ,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
       
      
         // const toastElement = toast.querySelector('.swal2-popup.swal2-toast') as HTMLElement; // Cast to HTMLElement
          //console.log(toastElement)
  //         if (toastElement) {
  //         toastElement.style.backgroundColor = backgroundColor + ' !important';
  //         toastElement.style.color = textColor  +' !important';
  //       }
   toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);




      },
    });

    Toast.fire({
      title: title,
    });
  }
  showErrorMessage(error: any, fallbackMessage: string, duration: number = 5000) {
    this.showToastWithout(fallbackMessage , '#DC4C64 ' , '#fff')
   
    // let errMsg: string = '';

    // if (error && error.error) {
    //   if (error.error.message) {
    //     errMsg = error.error.message;
    //   } else if (error.error.errors) {
    //     errMsg = Object.values(error.error.errors).flat().join(' ');
    //   }
    // }

    // if (!errMsg) {
    //   errMsg = fallbackMessage;
    // }

    // this.snackBar.openFromComponent(CustomSnackbarComponent, {
    //   horizontalPosition: 'end',
    //   verticalPosition: 'bottom',
    //   duration: duration,
    //   data: {
    //     message: errMsg,
    //     type: 'error'
    //   }
    // });
  }

}
