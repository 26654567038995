import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from 'src/app/shared/components/custom-snackbar/custom-snackbar.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriperService {
  actionFire = new BehaviorSubject<any>('')

  constructor() { }

  postAction(): Observable<any> {
    return this.actionFire.asObservable()
  }

}
