import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { MetaService } from './core/services/meta/meta.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { APP_BASE_HREF, DOCUMENT, Location, NgIf, isPlatformBrowser } from '@angular/common';
import { SubscriperService } from './core/services/subscriper/subscriper.service';

declare var WOW: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgIf],
  providers: [MetaService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private subscription: Subscription = new Subscription();
  action: any;
  loader: any;

  constructor(
    private metaService: MetaService,
    private router: Router,
    private localizeRouterService: LocalizeRouterService,
    private location: Location,
    @Inject(DOCUMENT) private dom: any,
    private renderer: Renderer2,
    private subcripeService: SubscriperService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.subscription.add(
      this.subcripeService.postAction().subscribe((res) => {
        this.action = res;
      })
    );
    this.onNavigationStart();
   // this.metaService.updateCanonicalUrl(`${this.location.path()}`);

   
  }

  ngOnInit(): void {
    this.checkLanguage();

    if (isPlatformBrowser(this.platformId)) {
      this.someFunction();

      // this.loader = this.renderer.selectRootElement('#loaders');
      // if (this.loader.style.display != 'none')
      //   this.loader.style.display = 'none';
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onNavigationStart(): void {
    // this.router.events.subscribe((event: NavigationStart | any) => {
    //   this.metaService.updateCanonicalUrl(`${this.router.url}`);
    // });
  }

  checkLanguage(): void {
    var lang = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE') 
    const htmlTag = document.documentElement; //--this.dom.getElementsByTagName('html')[0] as HTMLHtmlElement;
    const bodyTag = document.body; //--this.dom.getElementsByTagName('html')[0] as HTMLHtmlElement;
    
     if(lang!=null){
      if(lang == "ar"){
        htmlTag.setAttribute('dir','rtl')
        bodyTag.setAttribute('data-pc-direction','rtl')
        htmlTag.setAttribute('lang','ar')
        // htmlTag.dir = 'rtl';
        // htmlTag.lang = 'ar';
      }else{
        htmlTag.setAttribute('dir','ltr')
        bodyTag.setAttribute('data-pc-direction','ltr')
        htmlTag.setAttribute('lang','en')
        // htmlTag.dir = 'ltr';
        // htmlTag.lang = 'en';
      }
     }else{
      htmlTag.setAttribute('dir','ltr')
      bodyTag.setAttribute('data-pc-direction','ltr')
      htmlTag.setAttribute('lang','en')
      // htmlTag.dir = 'ltr';
      // htmlTag.lang = 'en';
     }

    // if (this.localizeRouterService.parser.currentLang == 'en') {
    //   htmlTag.dir = 'ltr';
    //   htmlTag.lang = 'en';
    // } else {
    //   htmlTag.dir = 'rtl';
    //   htmlTag.lang = 'ar';
    // }
  }

  someFunction() {
    return window.location;
  }

 


}
