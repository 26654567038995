import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
// import { LoginResponse } from '../../../applications/Internal-Managment-System/shared/interfaces/loginResponse';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubject$ = new BehaviorSubject<any>(null);

  constructor(private _router: Router, private _http: HttpClient) {}

  login(credentials: { userName: string; Password: string }): Observable<any> {
    return this._http
      .post<any>(environment.url + 'auth/login', credentials)
      .pipe(
        tap((response) => {
          if (response.data && response.data.token) {
            localStorage.setItem('tokenHr', response.data.token.access_token);
            localStorage.setItem(
              'userHr',
              JSON.stringify({
                fullName: response.data.user.name,
                email: response.data.user.email,
              })
            );

            this._router.navigate(['/en/home']);
          }
        })
      );
  }
  isLoggedIn(): boolean {
    return !!localStorage.getItem('tokenHr');
  }

  logOut(){
    console.log('logout')
    localStorage.removeItem('ecotoken');
    localStorage.removeItem('userRules');
   
    }

  private handleError(error: HttpErrorResponse) {
    let errorMsg = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMsg = `Error: ${error.error.error}`;
    } else {
      if (error.status === 400 && error.error.errorMessageAr) {
        errorMsg = error.error.errorMessageAr;
      } else {
        errorMsg = `Server returned code: ${error.status}, error message is: ${error.message}`;
      }
    }
    console.error(errorMsg);
    return throwError(() => new Error(errorMsg));
  }
}
